import React from "react";
import {url} from "../../../shared/URL";
import {Checkbox, Grid, Input} from "semantic-ui-react";
import FiltersComponent from "../../../components/FiltersComponent";
import CustomTable from "../../../components/CustomTable/CustomTable";
import TableStats from "../../../components/TableStats";
import useFetchTable from "../../../hooks/useFetchTable";
import {RangeDateInput, RangeInput} from "../../../components/FiltersComponent/FilterField";
import {BooleanCell, DateTimeCell} from "../../../components/CustomCell";
import RetailPointCard from "../../Cards/RetailPointCard";
import './retail-point.scss';

const RetailPointsPage = () => {
    const fetchTable = useFetchTable(url.getPoints);

    return <div className="page table-page">
        <h1 className="page__header">Список розничных точек</h1>
        <Grid stackable>
            <Grid.Row>
                <Grid.Column className="table-page__stats">
                    <TableStats totalElements={fetchTable.totalElements}/>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={3}>
                    <FiltersComponent applyFilters={fetchTable._apply} totalElements={fetchTable.totalElements}>
                        <Input type="text" name="name" placeholder="Название точки"/>
                        <Input type="text" name="inn" placeholder="ИНН организации"/>
                        <Input type="text" name="address" placeholder="Адрес"/>
                        <Checkbox name="confirmed" label="РТ подтверждена"/>
                        <Checkbox name="locked" label="РТ заблокирована"/>
                        <RangeDateInput name="createdTime" placeholder="Дата добавления" />
                        <RangeInput type="range" name="sellerAmount" placeholder="Количество продавцов"/>
                    </FiltersComponent>
                </Grid.Column>
                <Grid.Column width={13}>
                    <CustomTable table={fetchTable}
                                 columns={columns}
                                 CardComponent={RetailPointCard}/>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    </div>;
};

export default RetailPointsPage;

const columns = [
    {Header: "Название точки", accessor: "name"},
    {Header: "ИНН организации", accessor: "inn"},
    {Header: "Адрес", accessor: "address"},
    {Header: "Подтверждение РТ", accessor: "confirmed", Cell: row => BooleanCell(row)},
    {Header: "Блокировка РТ", accessor: "locked", Cell: row => BooleanCell(row)},
    {Header: "Дата добавления", accessor: "createdTime", Cell: row => DateTimeCell(row)},
    {Header: "ФИО АРТ", accessor: "retailPointAdminFullName"},
    {Header: "Количество продавцов", accessor: "sellerAmount"},
    {Header: "Количество начисленных бонусов", accessor: "chargedBonus"},
];