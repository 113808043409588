import React, {useEffect, useState} from "react";
import {client} from "../../shared/client";
import {url, navigation} from "../../shared/URL";
import queryString from "query-string";
import {Button, Form, Loader, Message} from "semantic-ui-react";
import {Formik} from "formik";
import * as Yup from 'yup';
import {appHistory} from "../../index";

export const PasswordRecoveryForm = () => {
    const [errorMessage, setErrorMessage] = useState('');
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(true);
    const [codeIsValid, setCodeIsValid] = useState(false);

    const requestId = window.location.pathname.split("/").pop();

    const _checkResetCode = () => {
        client.post(url.checkResetCode + requestId)
            .then(() => setCodeIsValid(true))
            .catch((error) => {
                setErrorMessage(error.response.data.message);
            })
            .finally(() => setLoading(false));
    };

    const _setNewPassword = ({password}) => {
        setLoading(true);
        client.post(
            url.resetPassword + requestId, queryString.stringify({password}), {
                headers: {'Content-Type': 'application/x-www-form-urlencoded'}
            })
            .then(() => {
                setSuccess(true);
                setTimeout(() => appHistory.push(navigation.auth), 6000);
            })
            .catch((error) => {
                setSuccess(false);
                setErrorMessage(error.response.data.message);
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        _checkResetCode();
    }, []);

    return <React.Fragment>
        <h1 className="authentication-page__header">Восстановление пароля</h1>
        {
            loading ? <Loader active/> :
                !codeIsValid ? <Message error header="Ошибка" content={errorMessage}/> :
                    success ?
                        <Message positive>
                            <Message.Header>Пароль был успешно изменен</Message.Header>
                            <p>Вы будете перенаправлены на страницу авторизации</p>
                        </Message>
                        :
                        <Formik initialValues={{password: '', passwordConfirmation: ''}} onSubmit={_setNewPassword}
                                validationSchema={Yup.object().shape({
                                    password: Yup.string().required('Обязательное поле'),
                                    passwordConfirmation: Yup.string().oneOf([Yup
                                        .ref('password'), null], "Пароли должны совпадать")
                                        .required('Обязательное поле')
                                })}>
                            {({values, errors, handleChange, handleSubmit}: any) => {
                                return <Form onSubmit={handleSubmit} className="authentication-page__form"
                                             error={errorMessage !== ''}>
                                    <Form.Field>
                                        <Form.Input type="password" name="password" onChange={handleChange}
                                                    value={values.password}
                                                    error={errors.password} placeholder="Введите новый пароль"/>

                                    </Form.Field>
                                    <Form.Field>
                                        <Form.Input type="password" name="passwordConfirmation" onChange={handleChange}
                                                    value={values.passwordConfirmation}
                                                    error={errors.passwordConfirmation}
                                                    placeholder="Повторите новый пароль"/>
                                    </Form.Field>
                                    <Message error header="Ошибка" content={errorMessage}/>
                                    <Button type="submit" className="button authentication-page__button"
                                            onClick={handleSubmit}>
                                        Изменить пароль
                                    </Button>
                                </Form>
                            }}
                        </Formik>
        }
    </React.Fragment>
};
