import React from "react";
import {Grid} from "semantic-ui-react";
import {Form} from "semantic-ui-react";
import "./details-page.scss";

const DetailsFieldComponent = props => {
    const {
        children,
        placeholder,
        disabled,
        value,
        renderCustomInput,
        renderButton,
        type,
        icon,
        hideP
    } = props;

    return (
        <Grid.Row className='details-modal__field'>
            <Grid.Column width={6}>{placeholder}</Grid.Column>
            <Grid.Column width={10}>
                {disabled ? (
                    !hideP && <p>{value !== undefined && value !== null && String(value)}</p>
                ) : renderCustomInput ? (
                    renderCustomInput(props)
                ) : (
                    <Form.Input
                        className='details-modal__input'
                        iconPosition={icon ? "left" : undefined}
                        type={type || "text"}
                        value={value || ""}
                        {...props}
                        children={undefined}
                    />
                )}
                {renderButton && renderButton()}
                {children}
            </Grid.Column>
        </Grid.Row>
    );
};
export default DetailsFieldComponent;
