import React from "react";
import {url} from "../../../shared/URL";
import {Grid, Input} from "semantic-ui-react";
import FiltersComponent from "../../../components/FiltersComponent";
import CustomTable from "../../../components/CustomTable/CustomTable";
import TableStats from "../../../components/TableStats";
import useFetchTable from "../../../hooks/useFetchTable";
import FileUploadButton from "../../../components/FileUploadButton";
import {DateCell, StatusCell} from "../../../components/CustomCell";
import ToolCard from "../../Cards/ToolCard";
import {saleStatusOptions} from "../../../shared/constants";

const ToolsPage = () => {
    const fetchTable = useFetchTable(url.getTools);

    const columns = [
        {Header: "Наименование инструмента", accessor: "modelName"},
        {Header: "Артикул", accessor: "vendorCode"},
        {Header: "Серийный номер", accessor: "sn"},
        {Header: "Id nfc метки", accessor: "nfc"},
        {
            Header: "Дата производства",
            accessor: "productionDate",
            Cell: (row) => DateCell(row),
        },
        {
            Header: "Статус продажи",
            accessor: "status",
            Cell: (row) => StatusCell(row, saleStatusOptions),
        },
    ];

    return (
        <div className='page table-page'>
            <h1 className='page__header'>Каталог выпущенных инструментов</h1>
            <Grid stackable>
                <Grid.Row>
                    <Grid.Column className='table-page__stats'>
                        <TableStats totalElements={fetchTable.totalElements} />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={3}>
                        <FiltersComponent
                            applyFilters={fetchTable._apply}
                            totalElements={fetchTable.totalElements}
                            render={() => (
                                <FileUploadButton
                                    url={url.importToolToDB}
                                    update={fetchTable._fetch}
                                />
                            )}
                        >
                            <Input
                                type='text'
                                name='modelName'
                                placeholder='Наименование инструмента'
                            />
                            <Input
                                type='text'
                                name='vendorCode'
                                placeholder='Артикул'
                            />
                            <Input
                                type='text'
                                name='sn'
                                placeholder='Серийный номер'
                            />
                            <Input
                                type='text'
                                name='nfc'
                                placeholder='Id nfc метки'
                            />
                        </FiltersComponent>
                    </Grid.Column>
                    <Grid.Column width={13}>
                        <CustomTable
                            table={fetchTable}
                            columns={columns}
                            CardComponent={ToolCard}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
    );
};
export default ToolsPage;
