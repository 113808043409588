import React, { useState } from "react";
import Table from "react-table";
import { Icon } from "semantic-ui-react";
import "./custom-table.scss";

const CustomTable = props => {
    const { CardComponent, cardContent, table } = props;
    const { loading, content, size, totalPages, totalElements } = table.table;

    const [openCard, setOpenCard] = useState<boolean>(false);
    const [rowContent, setRowContent] = useState<Object | undefined>(undefined);

    const update = table._fetch;

    return (
        <React.Fragment>
            <Table
                data={content}
                pages={totalPages}
                loading={loading}
                page={table.page}
                onPageChange={newPage => table.pageChange(newPage)}
                onPageSizeChange={table.onPageSizeChange}
                onSortedChange={table._sort}
                update={update}
                manual
                getTrProps={
                    CardComponent
                        ? (state, row) => ({
                              onClick: () => {
                                  setRowContent(row.original);
                                  setOpenCard(!openCard);
                              },
                              className: row ? "custom-table_clickable" : ""
                          })
                        : undefined
                }
                pageSizeOptions={[10, 50, 100]}
                defaultPageSize={10}
                showPagination={(totalElements || 100) > size}
                nextText={<Icon name="chevron right" />}
                previousText={<Icon name="chevron left" />}
                paginationText="Страница"
                noDataText="Данные не были найдены"
                pageText="Страница"
                loadingText="Загрузка..."
                ofText="из"
                rowsText="строк"
                {...props}
                className={`custom-table -striped ${props.className}`}
                minRows={1}
            />
            {CardComponent && openCard && (
                <CardComponent
                    content={cardContent || rowContent}
                    update={() => update && update()}
                    close={() => setOpenCard(false)}
                    table={table}
                />
            )}
        </React.Fragment>
    );
};

export default CustomTable;
