import React from "react";
import {filterURL, url} from "../../../shared/URL";
import {Grid, Input} from "semantic-ui-react";
import CustomTable from "../../../components/CustomTable/CustomTable";
import FiltersComponent from "../../../components/FiltersComponent";
import useFetchTable from "../../../hooks/useFetchTable";
import ConfirmRPCard from "../../Cards/RetailPointCard/ConfirmRPCard";
import '../RetailPointsPage/retail-point.scss';

const NotConfirmedRetailPoints = () => {
    const fetchTable = useFetchTable(url.getProposals, `${filterURL.roleAdmin};${filterURL.notConfirmedRP}`);

    return <div className="page table-page retail-point-page">
        <h1 className="page__header">Подтверждение розничных точек</h1>
        <Grid stackable>
            <Grid.Row>
                <Grid.Column width={3}>
                    <FiltersComponent applyFilters={fetchTable._apply} totalElements={fetchTable.totalElements}>
                        <Input type="text" name="name" placeholder="Название точки"/>
                        <Input type="text" name="inn" placeholder="ИНН организации"/>
                        <Input type="text" name="address" placeholder="Адрес"/>
                    </FiltersComponent>
                </Grid.Column>
                <Grid.Column width={13}>
                    <CustomTable table={fetchTable}
                                 columns={columns}
                                 CardComponent={ConfirmRPCard}/>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    </div>;
};

export default NotConfirmedRetailPoints;

const columns = [
    {Header: "ИНН организации", accessor: "retailPoint.inn", id: "inn",},
    {Header: "Название точки", accessor: 'retailPoint.name', id: "name"},
    {Header: "Адрес", accessor: "retailPoint.address", id: "address"},
    {Header: "ФИО руководителя", accessor: "retailPoint.directorFullName", id: "directorFullName", sortable: false},
    {
        Header: "ФИО АРТ",
        accessor: "user",
        id: "user",
        Cell: ({value}) => `${value.username} ${value.surname} ${!value.noPatronymic && value.patronymic}`,
        sortable: false
    },
    {
        Header: "Номер телефона руководителя",
        accessor: "retailPoint.directorPhone",
        id: "directorPhone",
        sortable: false
    },
];
