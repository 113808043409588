import React, { useState, useEffect } from "react";
import { Button, Input, Form, Icon } from "semantic-ui-react";
import ReactFileReader from "react-file-reader";
import Messages from "./Messages";
import { client } from "../../../shared/client";
import { url } from "../../../shared/URL";
import { sendMessage } from "../../../shared/ws";
import "./chat.scss";


interface ChatProps {
  setChatId: Function,
  content: any,
  update: any,
  iAm: string,
  chatId: number | null,
  count: number
}


const Chat = ({ update, content, setChatId, iAm, chatId, count }: ChatProps) => {
  const [newMessage, setNewMessage] = useState<string>("");
  const [chat, setChat] = useState();
  const [file, setFile] = useState<any>(undefined);
  const [fileName, setFilename] = useState<string | null>(null);
  const [messages, setMessages] = useState();
  const [admin, setAdmin] = useState<Record<string, any>>();

  useEffect(() => {
    if (content) {
      setChat(content);
      setMessages(content.messages);
      setAdmin(content.admin);
    }
  }, [content, chatId]);

  useEffect(() => {
    if (file) {
      const formData = new FormData();
      formData.append("file", file[0]);
      client.post(url.files, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
            .then((response) => {
              setFilename(response.data);
            })
            .catch(() => {
            })
            .finally(() => {
              setFile(undefined);
            });
    }
  }, [file]);

  const assign = async () => {
    await client.put(`${url.chats}/${chatId}`)
                .then(() => {
                  update(count + 1);
                });
  };

  const assignMySelf = async () => {
    await client.put(`${url.chats}/assign/myself/${chatId}`)
                .then(() => {
                  update(count + 1);
                });
  }

  const submitMessage = async () => {
    if (newMessage.length > 0 || fileName) {
      const data = {
        message: {
          message: newMessage,
          file: fileName,
        },
        chatId: chatId,
      };
      // setMessages([...messages, data.message]);
      sendMessage(data);
      setNewMessage("");
      setFilename(null);
    }
    setNewMessage("");
    setFilename(null);
    update(count + 1);
  };

  const completeChat = async () => {
    await client.delete(`${url.chats}/${chatId}`)
                .then(() => {
                  setChatId(null);
                });
    update(count + 1);
  };

  return (
    <div className="chat">
      {chat && <div className="chat__header">
        <div
          className="chat__header--assign"
        >
          {admin && admin.email === iAm
            ? `Назначено на меня` : (admin === null
              ? "Не назначен" : `Назначено на ${admin && admin.email}`)}
        </div>
        <div className="chat__header--name">
          Чат
        </div>
        {admin && admin.email === iAm
          ? <Button
            floated="right"
            onClick={completeChat}
          >
            Завершить
          </Button>
          : <Button
            className="button_positive"
            onClick={admin && admin.email ? assignMySelf : assign}
            disabled={!(chatId)}
          >
            Назначить на себя
          </Button>}
      </div>
      }
      <div className="chat__field">
        <Messages messages={messages} chatId={chatId} />
        <div className="chat__field__input">
          <Form onSubmit={() => submitMessage()}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Input
                onChange={(event) => setNewMessage(event.target.value)}
                disabled={!chatId || (admin && admin.email !== iAm) || admin === null || !!fileName}
                value={newMessage}
                style={{ width: "100%" }}
                placeholder='Сообщение...'
              />
              <Button
                icon="send"
                content="Отправить"
                labelPosition='right'
                disabled={!chatId || (admin && admin.email !== iAm) || admin === null}
              />
            </div>
          </Form>
          <div className={`chat__field__input--upload ${fileName && "chat__field__input--upload-success"}`}>
            <ReactFileReader handleFiles={setFile}>
              <button hidden={!chatId || (admin && admin.email !== iAm) || admin === null || !!newMessage.length}>
                <Icon name='paperclip' />
              </button>
            </ReactFileReader>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chat;
