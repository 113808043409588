import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "core-js";
import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { Route, Router, Switch } from "react-router";
import { navigation } from "./shared/URL";
import AuthenticationPage from "./pages/AuthenticationPage";
import MainPage from "./pages/MainPage";
import { createBrowserHistory } from "history";
import { NotificationEmitter } from "./components/NotificationsEmitter";
import "./static/index.scss";
import "semantic-ui-css/semantic.min.css";
import "react-table/react-table.css";


export const appHistory = createBrowserHistory({ basename: "" });


interface SentryInterface {
  dsn: string;
  // integrations: any;
  tracesSampleRate: number;
}

const options: SentryInterface = {
  dsn: "https://d26d55afe541474eb88ec163c503b59f@bug-tracker.mct.tools//7",
  // integrations: [new Integrations.BrowserTracing({
  //   routingInstrumentation: Sentry.reactRouterV5Instrumentation(appHistory),
  // })],
  tracesSampleRate: 1.0,
};

Sentry.init(options);

ReactDOM.render(
  <div className="smart-tools-app container">
    <Router history={appHistory}>
      <Switch>
        <Route path={navigation.auth} component={AuthenticationPage} />
        <Route component={MainPage} />
      </Switch>
      <NotificationEmitter />
    </Router>
  </div>,
  document.getElementById("root"),
);
