import React, {useEffect, useState} from "react";
import {Menu, Dropdown, Label} from "semantic-ui-react";
import {AxiosResponse} from "axios";
import {Link} from "react-router-dom";
import {client} from "../../shared/client";
import {Auth} from "../../pages/MainPage";
import Logo from "../Logo";
import {filterURL, navigation, url} from "../../shared/URL";
import "./navigation.scss";

const Navigation = () => {
    const [usersCount, setUsersCount] = useState<number>(0);
    const [pointsCount, setPointsCount] = useState<number>(0);
    const [salesCount, setSalesCount] = useState<number>(0);
    const [salesUnknownToolsCount, setSalesUnknownToolsCount] = useState<number>(0);
    const [open, setOpen] = useState<boolean>(false);

    const _logout = (): void => {
        client
            .post(url.logout)
            .then((): void => {
                Auth.auth = false;
                Auth.Login = '';
                Auth.Password = '';
            })
            .catch((): void => {
            });
    };

    const _getNotificationsCount = (): void => {
        client
            .post(`${url.getProposalsCount}/${filterURL.prefix}${filterURL.notConfirmedRP}${filterURL.separator}${filterURL.roleAdmin}`)
            .then(
                ({data}: AxiosResponse): void => {
                    pointsCount !== data.payload && setPointsCount(data.payload)
                }
            )
            .catch((): void => {
            });
        client
            .post(`${url.getUserCount}/${filterURL.prefix}${filterURL.notConfirmedUsers}`)
            .then(({data}: AxiosResponse): void => {
                usersCount !== data.payload && setUsersCount(data.payload)
            })
            .catch((): void => {
            });
        client
            .post(`${url.getSalesCount}/${filterURL.prefix}${filterURL.notConfirmedSales}`)
            .then(({data}: AxiosResponse): void => {
                salesCount !== data.payload && setSalesCount(data.payload)
            })
            .catch((): void => {
            });
        client
            .get(`${url.getSalesUnknownCount}/${filterURL.prefix}${filterURL.notKnown};${filterURL.isNotRejected}`)
            .then(({data}: AxiosResponse): void => {
                salesUnknownToolsCount !== data.payload && setSalesUnknownToolsCount(data.payload)
            })
            .catch((): void => {
            });
    };

    useEffect((): void => {
        open && _getNotificationsCount();
    }, [open]);

    return (
        <div className="navigation">
            <Menu stackable className="navigation__menu menu borderless">
                <div className="menu__logo">
                    <Link to={navigation.sales}>
                        <Logo/>
                    </Link>
                </div>
                <Dropdown item text="Инструменты">
                    <Dropdown.Menu className="menu__dropdown">
                        <Menu.Item as={Link} to={navigation.models}>
                            Каталог моделей инструментов
                        </Menu.Item>
                        <Menu.Item as={Link} to={navigation.tools}>
                            Каталог выпущенных инструментов
                        </Menu.Item>
                    </Dropdown.Menu>
                </Dropdown>
                <Dropdown item text="Пользователи">
                    <Dropdown.Menu className="menu__dropdown">
                        <Menu.Item as={Link} to={navigation.buyers}>
                            Покупатели
                        </Menu.Item>
                        <Menu.Item as={Link} to={navigation.sellers}>
                            Продавцы
                        </Menu.Item>
                        <Menu.Item as={Link} to={navigation.users}>
                            Все пользователи
                        </Menu.Item>
                        <Menu.Item as={Link} to={navigation.payments}>
                            Список заявок на вывод
                        </Menu.Item>
                    </Dropdown.Menu>
                </Dropdown>
                <Dropdown item text="Продажи">
                    <Dropdown.Menu className="menu__dropdown">
                        <Menu.Item as={Link} to={navigation.sales}>
                            Список продаж
                        </Menu.Item>
                        <Menu.Item as={Link} to={navigation.receipts}>
                            Список чеков
                        </Menu.Item>
                    </Dropdown.Menu>
                </Dropdown>
                <Menu.Item as={Link} to={navigation.retailPoints}>
                    Розничные точки
                </Menu.Item>
                <Dropdown
                    item
                    text="Подтверждение"
                    lazyLoad={true}
                    open={open}
                    onClose={() => setOpen(false)}
                    onOpen={() => setOpen(true)}>
                    <Dropdown.Menu className="menu__dropdown">
                        <Menu.Item as={Link} to={navigation.notConfirmedRP}>
                            <Label circular horizontal className="confirm-notification">
                                {pointsCount}
                            </Label>
                            Подтверждение розничных точек
                        </Menu.Item>
                        <Menu.Item as={Link} to={navigation.notConfirmedUsers}>
                            <Label circular horizontal className="confirm-notification">
                                {usersCount}
                            </Label>
                            Подтверждение данных для вывода денег
                        </Menu.Item>
                        <Menu.Item as={Link} to={navigation.notConfirmedSales}>
                            <Label circular horizontal className="confirm-notification">
                                {salesCount}
                            </Label>
                            Подтверждение продажи
                        </Menu.Item>
                        <Menu.Item as={Link} to={navigation.notConfirmedSalesUnknownTools}>
                            <Label circular horizontal className="confirm-notification">
                                {salesUnknownToolsCount}
                            </Label>
                            Добавление информации о товаре
                        </Menu.Item>
                    </Dropdown.Menu>
                </Dropdown>
                <Menu.Item as={Link} to={navigation.reports}>
                    Отчеты
                </Menu.Item>
                <Dropdown item text="Поддержка">
                    <Dropdown.Menu className="menu__dropdown">
                        <Menu.Item as={Link} to={navigation.activeDialogs}>
                            Активные диалоги
                        </Menu.Item>
                        <Menu.Item as={Link} to={navigation.completedDialogs}>
                            Завершенные диалоги
                        </Menu.Item>
                    </Dropdown.Menu>
                </Dropdown>
                <Dropdown item icon="user" className="right">
                    <Dropdown.Menu className="menu__dropdown" direction="left">
                        <Menu.Item as={Link} to={navigation.admins}>
                            Список администраторов
                        </Menu.Item>
                        <Menu.Item onClick={_logout}>Выйти</Menu.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </Menu>
        </div>
    );
};
export default Navigation;
