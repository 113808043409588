import React from "react";
import {format} from "date-fns";
import {Icon} from "semantic-ui-react";
import {getOptionText} from "../../shared/utils";

import "./custom-cell.scss";

export const BooleanCell = ({value}) => {
    return (
        <div className='boolean-cell'>
            {value === null ? (
                ""
            ) : value ? (
                <Icon name='check' size='large' color='green' />
            ) : (
                <Icon name='close' size='large' color='red' />
            )}
        </div>
    );
};


export const StatusCell = (row: { value }, options) => {
    if ((!Boolean(row) && !Boolean(row.value)) || (Array.isArray(row.value) && !Boolean(row.value.length))) {
        return "";
    }
    // роль пользователя приходит в массиве с одним элементом
    const value = Array.isArray(row.value) ? row.value[0] : row.value;
    return getOptionText(value, options);
};

export const DateCell = (row) => {
    return Boolean(row.value) && format(new Date(row.value), "dd/MM/yyyy");
};

export const DateTimeCell = (row) => {
    return Boolean(row.value) && format(new Date(row.value), "dd/MM/yyyy HH:mm");
};
