import React from "react";
import { Grid, Input } from "semantic-ui-react";
import TableStats from "../../components/TableStats";
import FiltersComponent from "../../components/FiltersComponent";
import CustomTable from "../../components/CustomTable/CustomTable";
import { DateTimeCell } from "../../components/CustomCell";
import { RangeDateInput } from "../../components/FiltersComponent/FilterField";
import CompletedChatCard from "../Cards/CompletedChatCard";
import useFetchTable from "../../hooks/useFetchTable";
import { url } from "../../shared/URL";


const CompletedDialogs = () => {
  const fetchTable = useFetchTable(url.chatsAnswered);

  return (
    <div className='page table-page'>
      <h1 className='page__header'>Завершенные диалоги</h1>
      <Grid stackable>
        <Grid.Row>
          <Grid.Column className='table-page__stats'>
            <TableStats totalElements={fetchTable.totalElements} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={3}>
            <FiltersComponent
              applyFilters={fetchTable._apply}
              totalElements={fetchTable.totalElements}
            >
              <RangeDateInput
                name="created"
                placeholder="Дата обращения"
              />
              <Input
                type='text'
                name='user'
                placeholder='ФИО пользователя'
              />
              <Input
                type='number'
                name='mobile'
                placeholder='Номер телефона'
              />
              <Input
                type='text'
                name='admin'
                placeholder='ФИО оператора'
              />
            </FiltersComponent>
          </Grid.Column>
          <Grid.Column width={13}>
            <CustomTable
              table={fetchTable}
              columns={columns}
              CardComponent={CompletedChatCard}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default CompletedDialogs;

const columns = [
  {
    Header: "ФИО пользователя",
    id: "user",
    accessor: row => `${row.user.surname} ${row.user.username} ${row.user.patronymic}`,
  },
  { Header: "Номер телефона", accessor: "user.mobile", id: "mobile" },
  { Header: "Дата обращения", accessor: "messages[0].created", Cell: row => DateTimeCell(row), id: "created" },
  { Header: "Вопрос", accessor: "messages[0].message" },
  { Header: "ФИО оператора", accessor: "admin.fullName", id: "admin" },
];
