import {Grid} from "semantic-ui-react";
import {Map, Placemark, YMaps} from "react-yandex-maps";
import React from "react";

const RetailPointMapComponent = ({coordinates}: { coordinates: string }) => {
    const unsetCoordinates = coordinates && coordinates.split(":").length < 1;
    const parsedCoordinates = coordinates.split(":").map(coordinates => Number(coordinates));

    return <Grid.Row>
        {parsedCoordinates && !unsetCoordinates && (
            <Grid.Column width={16}>
                <h3>На карте:</h3>
                <YMaps>
                    <Map
                        defaultState={{
                            center: parsedCoordinates,
                            zoom: 9,
                            controls: [
                                "fullscreenControl"
                            ]
                        }}
                        modules={[
                            "control.FullscreenControl"
                        ]}
                        className='map'
                    >
                        <Placemark
                            defaultGeometry={
                                parsedCoordinates
                            }
                        />
                    </Map>
                </YMaps>
            </Grid.Column>
        )}
        {unsetCoordinates && <Grid.Column>
            {coordinates}
        </Grid.Column>}
    </Grid.Row>
};

export default RetailPointMapComponent;