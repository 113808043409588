import React, { useState, useEffect } from "react";
import DetailsFieldComponent from "../DetailsFieldComponent";
import { Form, Grid, Modal, Button, Icon } from "semantic-ui-react";
import { Formik } from "formik";
import { AxiosResponse } from "axios";
import { client } from "../../../shared/client";
import { url, filterURL } from "../../../shared/URL";
import useFetchTable from "../../../hooks/useFetchTable";
import { errorNotify, successNotify } from "../../../components/NotificationsEmitter";
import SuccessDataSaveNotify from "../SuccessDataSaveNotify";
import SelectTable from "../../../components/CustomTable/SelectTable";
import "../details-page.scss";
import "./confirm-sale.scss";


const ConfirmSaleCard = ({ content, update, close }) => {
  const [open] = useState<boolean>(true);
  const [receiptItems, setReceiptItems] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [cardContent, setCardContent] = useState<any>(content);
  const [selectedItem, setSelectedItem] = useState<string>("");

  const id = content.id;
  const fetchTable = useFetchTable(url.getSales);

  const _getReceipt = (): void => {
    client
    .post(`${url.getReceiptBySaleId}/${id}`)
    .then(response => setReceiptItems(response.data.payload.items))
    .catch(() => {
    })
    .finally(() => {
    });
  };

  useEffect((): () => void => {
    open && _getReceipt();
    return (): void => {
      reset();
    };
  }, [open]);

  const _confirmItem = (itemName: string): Promise<AxiosResponse> => {
    setLoading(true);
    return client.post(`${url.addItemToSale}/${id}`, itemName, { headers: { "Content-Type": "text/plain" } });
  };

  const _getTopCard = (): void => {
    client
    .post(
      `${url.getSales}/${filterURL.prefix}${filterURL.notConfirmedSales}`,
      { offset: 0, size: 1 },
    )
    .then((response: AxiosResponse): void => {
      setCardContent(response.data.payload.content);
      if (!response.data.payload.content.length) {
        successNotify(
          "Чеков для подтверждения не осталось",
          "Окно будет закрыто через 3 секунды",
        );
        setTimeout((): void => {
          close();
          update();
        }, 3000);
      }
    })
    .catch((): void => {
    })
    .finally((): void => {
    });
  };

  const confirmAndContinue = (): void => {
    selectedItem
      ? _confirmItem(selectedItem)
      .then((): void => {
        _getTopCard();
        SuccessDataSaveNotify();
      })
      .catch((): void => {
      })
      .finally((): void => setLoading(false))
      : errorNotify("Ошибка", "Отсутствует наименование в чеке");
  };

  const _decline = (): void => {
    setLoading(true);
    client
    .post(`${url.rejectSale}/${id}`)
    .then((): void => {
      successNotify("Отклонена", "Продажа была успешно отклонена");
      close();
      update();
    })
    .catch((): void => {
    })
    .finally((): void => setLoading(false));
  };

  const reset = (): void => {
    setReceiptItems([]);
    setLoading(false);
  };

  const columns = [
    { Header: "Наименование в чеке", accessor: "name" },
    { Header: "Цена в чеке", accessor: "price" },
  ];

  return (
    <Modal open={open} onClose={close} closeIcon className="details-modal">
      <Modal.Header>Подтверждение продажи</Modal.Header>
      <Modal.Content className="details-modal__content">
        <Formik
          initialValues={{
            modelName: cardContent.modelName,
            sn: cardContent.sn,
            price: cardContent.price,
            modelVendorCode: cardContent.modelVendorCode,
            modelMsrp: cardContent.modelMsrp,
          }}
          onSubmit={() => {
          }}
          validateOnChange={false}
        >
          {({ values, handleSubmit }: any) => {
            return (
              <Form onSubmit={handleSubmit} noValidate>
                <Grid className="details-modal__grid" stackable>
                  <DetailsFieldComponent
                    value={values.modelName}
                    name="modelName"
                    placeholder="Наименование инструмента"
                    disabled
                  />
                  <DetailsFieldComponent
                    value={values.sn}
                    name="sn"
                    placeholder="Серийный номер"
                    disabled
                  />
                  <DetailsFieldComponent
                    value={values.modelMsrp}
                    name="modelMsrp"
                    placeholder="РРЦ"
                    disabled
                  />
                  <h3>Позиции в чеке</h3>
                  <Grid.Row>
                    <Grid.Column width="16">
                      <SelectTable
                        table={fetchTable}
                        data={receiptItems}
                        pagination={false}
                        showPagination={false}
                        defaultPageSize={0}
                        columns={columns}
                        loading={false}
                        setSelectedValue={value =>
                          setSelectedItem(value)
                        }
                        columnNameToSelect="name"
                        className="confirm-sale__table"
                      />
                    </Grid.Column>
                    <Grid.Column
                      width="16"
                      className="details-modal__buttons"
                    >
                      <Button
                        type="submit"
                        labelPosition="right"
                        onClick={_decline}
                        loading={loading}
                        icon
                      >
                        Отклонить
                        <Icon name="ban" />
                      </Button>
                      <Button
                        type="submit"
                        className="button_positive"
                        labelPosition="right"
                        onClick={confirmAndContinue}
                        loading={loading}
                        icon
                      >
                        Подтвердить и продолжить
                        <Icon name="arrow right" />
                      </Button>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </Modal.Content>
    </Modal>
  );
};

export default ConfirmSaleCard;
