import React, {ReactElement, useLayoutEffect, useRef} from 'react';
import {find} from 'lodash';
import NotificationSystem from 'react-notification-system';
import '../static/sticky-header.scss';

interface NotificationObject {
    title: string;
    message: string;
    level: 'success' | 'error' | 'warning' | 'info';
    dismissible: 'both' | 'button' | 'click';
    autoDismiss: number;
    unique?: boolean;
}

class NotificationSubject {
    ref: any = undefined;

    private addUniqueNotification(notification: NotificationObject): void {
        const notifications = this.ref.current.state.notifications;
        const unique = !find(notifications, ({message}: NotificationObject) => message === notification.message);
        unique && this.addNotification(notification);
    }

    private addNotification(notification: NotificationObject): void {
        this.ref && this.ref.current && this.ref.current.addNotification(notification);
    }

    public notify(notification: NotificationObject): void {
        Boolean(notification.unique) ? this.addUniqueNotification(notification) :
            this.addNotification(notification);
    }

    public addRef(ref): void {
        this.ref = ref;
    }
}

const notificationSubject = new NotificationSubject();

const successNotify = (title: string, message: string): void => notificationSubject.notify({
    title,
    message,
    autoDismiss: 0,
    dismissible: "button",
    level: "success"
});

const errorNotify = (title: string, message: string): void => notificationSubject.notify({
    title,
    message,
    autoDismiss: 0,
    dismissible: "button",
    level: "error"
});

const uniqueErrorNotify = (title: string, message: string): void => notificationSubject.notify({
    title,
    message,
    autoDismiss: 0,
    dismissible: "button",
    level: "error",
    unique: true
});

const NotificationEmitter = (): ReactElement => {
    const notificationSystem = useRef(NotificationSystem);

    useLayoutEffect((): void => {
        notificationSubject.addRef(notificationSystem);
    }, []);

    return <NotificationSystem ref={notificationSystem}/>
};

export {successNotify, errorNotify, uniqueErrorNotify, NotificationEmitter};