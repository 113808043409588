import React, { useState } from "react";
import { Dimmer, Image, Loader } from "semantic-ui-react";
import ImagesGallery from "../../../../../components/ImagesGallery";
import './card-images.scss';


const CardImages = ({loading, passportImages}) => {
	const [showAllImages, setShowAllImages] = useState<boolean>(false);

	return (
		<>
			{passportImages && passportImages.length > 0 ? <div className='card-images'>
					<Image.Group size='small'>
						{showAllImages ? passportImages.map((item, index) => {
								return <ImagesGallery
									imageUrl={`${item}`}
									key={index}
									filter={false}
								/>;
							})
							: <>
								<ImagesGallery
									imageUrl={`${passportImages[0]}`}
									filter={false}
								/>
								{passportImages.length > 1 &&
								<ImagesGallery
									imageUrl={`${passportImages[1]}`}
									filter={true}
									setShowAllImages={setShowAllImages}
								/>}
							</>}
					</Image.Group>
				</div>
				: (loading && <div className='card-images--loader'>
					<Dimmer active inverted>
						<Loader>Загрузка изображений...</Loader>
					</Dimmer>
				</div>)
			}
		</>
	);
};

export default CardImages;
