import React, { useState, useEffect } from "react";
import { Auth } from "../MainPage";
import Dialogs from "./Dialogs";
import Chat from "./Chat/Chat";
import UserInfo from "./UserInfo";
import { client } from "../../shared/client";
import { url } from "../../shared/URL";
import { taxStatusOptions, userStatusOptions } from "../../shared/constants";
import { findChat, userStatusInnerText } from "../../shared/utils";
import { connect } from "../../shared/ws";
import "./active-dialogs.scss";


const DialogsHeader = (props) => {
  const { column1, column2 } = props;
  return (
    <div className="active-dialogs__header">
      <div>
        {column1.name}
      </div>
      {column2 && <div>
        {column2.name}
      </div>}
    </div>

  );
};

const ActiveDialogs = () => {
  const [count, forceUpdate] = useState<number>(0);
  const [chatId, setChatId] = useState<number | null>(null);
  const [chats, setChats] = useState<Record<string, any>[]>();
  const myContent: Array<object> = [];
  const activeContent: Array<object> = [];
  const processContent: Array<object> = [];
  const [incomingMessages, setIncomingMessages] = useState([]);

  const fetchChats = async () => {
    await Promise.all([
      client.get(`${url.chatsActive}`),
      client.get(`${url.chatsProcess}`),
    ]).then((res) => {
      setChats(res[0].data.payload.concat(res[1].data.payload));
    });
  };

  useEffect(() => {
    fetchChats()
  }, [count, incomingMessages]);

  useEffect(() => {
    connect(incomingMessages, setIncomingMessages, Auth.Login, Auth.Password);
  }, []);

  const chatContent = chats && findChat(chats, chatId);

  const sortContentByType = () => {
    if (chats) {
      for ( let chat of chats as any ) {
        switch (chat && chat.chatStatus) {
          case "ACTIVE":
            activeContent.push(chat);
            break;
          case "PROCESS":
            chat.admin && chat.admin.email === Auth.Login
              ? myContent.push(chat)
              : processContent.push(chat);
            break;
        }
      }
    }
  };
  sortContentByType();

  return (
    <div className="page table-page">
      <h1 className="page__header">Активные диалоги</h1>
      <div className="active-dialogs">
        <div className="active-dialogs__category">
          <DialogsHeader column1={{ name: "Диалоги:" }} column2={{ name: "Сообщения:" }} />
          <Dialogs header={"Новые:"} content={activeContent} setChatId={setChatId} count={count} update={forceUpdate} />
          <Dialogs
            header={"Мои диалоги:"}
            content={myContent}
            setChatId={setChatId}
            count={count}
            update={forceUpdate}
          />
          <Dialogs
            header={"Остальные:"}
            content={processContent}
            setChatId={setChatId}
            count={count}
            update={forceUpdate}
          />
        </div>

        <div className="active-dialogs__chat">
          <Chat
            content={chatContent && chatContent}
            setChatId={setChatId}
            iAm={Auth.Login}
            chatId={chatId}
            count={count}
            update={forceUpdate}
          />
        </div>

        <div className="active-dialogs__user-info">
          <DialogsHeader column1={{ name: "Пользователь" }} />
          <UserInfo
            placeholder={"ФИО"}
            value={chatContent && `${chatContent.user.surname} ${chatContent.user.username} ${chatContent.user.patronymic}`}
          />
          <UserInfo
            placeholder={"Статус"}
            value={chatContent && userStatusInnerText(chatContent.user.status, userStatusOptions)}
          />
          <UserInfo
            placeholder={"Статус ФНС"}
            value={chatContent && userStatusInnerText(chatContent.user.taxStatus, taxStatusOptions)}
          />
          <UserInfo
            placeholder={"Телефон"}
            value={chatContent && chatContent.user.mobile}
          />
        </div>
      </div>
    </div>
  );
};

export default ActiveDialogs;
