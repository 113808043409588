import React, {useState} from "react";
import {Button, Icon, Form, Grid, Modal} from "semantic-ui-react";
import {Formik} from "formik";
import * as Yup from "yup";
import DetailsFieldComponent from "../DetailsFieldComponent";
import SuccessDataSaveNotify from "../SuccessDataSaveNotify";
import {validationErrorMessages} from "../../../shared/constants";
import {client} from "../../../shared/client";
import {url} from "../../../shared/URL";
import {formatDateTime, getModifiedValues} from "../../../shared/utils";
import "../details-page.scss";

const ToolCard = props => {
  const {content, update, close} = props;

  const [open] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);

  const initialValues = {
    modelName: content.modelName,
    sn: content.sn,
    nfc: content.nfc,
    vendorCode: content.vendorCode,
    saleDate: content.saleDate,
    productionDate: content.productionDate
  };

  const cancelChanges = () => {
    setLoading(false);
    close();
  };

  const _save = values => {
    const modifiedValues = getModifiedValues(values, initialValues);
    setLoading(true);
    client
      .post(url.editTool, {
        [content.id]: {
          ...modifiedValues
        }
      })
      .then(() => {
        SuccessDataSaveNotify();
        update();
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
        close();
      });
  };

  return (
    <Modal open={open} onClose={close} closeIcon className='details-modal'>
      <Modal.Header>Описание выпущенного инструмента</Modal.Header>
      <Modal.Content className='details-modal__content'>
        <Formik
          initialValues={initialValues}
          onSubmit={_save}
          validationSchema={Yup.object().shape({
            modelName: Yup.string().required(validationErrorMessages.required),
            sn: Yup.string().required(validationErrorMessages.required),
            nfc: Yup.string().required(validationErrorMessages.required),
            vendorCode: Yup.string().required(validationErrorMessages.required)
          })}
          validateOnChange={false}
          key={content.id}
        >
          {({values, errors, handleChange, handleSubmit}: any) => {
            return (
              <Form onSubmit={handleSubmit} noValidate>
                <Grid className='details-modal__grid' stackable>
                  <DetailsFieldComponent
                    value={values.modelName}
                    error={errors.modelName}
                    onChange={handleChange}
                    name='modelName'
                    placeholder='Наименование инструмента'
                    disabled
                  />
                  <DetailsFieldComponent
                    value={values.vendorCode}
                    error={errors.vendorCode}
                    onChange={handleChange}
                    name='vendorCode'
                    placeholder='Артикул'
                    disabled
                  />
                  <DetailsFieldComponent
                    value={values.sn}
                    error={errors.sn}
                    onChange={handleChange}
                    name='sn'
                    placeholder='Серийный номер'
                  />
                  <DetailsFieldComponent
                    value={values.nfc}
                    error={errors.nfc}
                    onChange={handleChange}
                    name='nfc'
                    placeholder='Id nfc метки'
                  />
                  <DetailsFieldComponent
                    value={formatDateTime(values.productionDate)}
                    error={errors.productionDate}
                    onChange={handleChange}
                    name='productionDate'
                    placeholder='Дата производства'
                    disabled
                  />
                  <DetailsFieldComponent
                    value={formatDateTime(values.saleDate)}
                    error={errors.saleDate}
                    onChange={handleChange}
                    name='saleDate'
                    placeholder='Дата продажи'
                    disabled
                  />
                  <Grid.Column width='16' className='details-modal__buttons'>
                    <Button
                      type='button'
                      onClick={cancelChanges}
                      labelPosition='right'
                      icon
                    >
                      Отменить
                      <Icon name='close' />
                    </Button>
                    <Button
                      type='submit'
                      className='button_positive'
                      labelPosition='right'
                      loading={loading}
                      icon
                    >
                      Сохранить
                      <Icon name='arrow right' />
                    </Button>
                  </Grid.Column>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </Modal.Content>
    </Modal>
  );
};

export default ToolCard;
