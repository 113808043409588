import React, {useState} from "react";
import {YMaps, Map, Placemark} from "react-yandex-maps";
import {Formik} from "formik";
import * as Yup from "yup";
import {Button, Form, Grid, Icon, Modal} from "semantic-ui-react";
import SuccessDataSaveNotify from "../SuccessDataSaveNotify";
import DetailsFieldComponent from "../DetailsFieldComponent";
import {notificationMessages, validationErrorMessages} from "../../../shared/constants";
import {client} from "../../../shared/client";
import {url} from "../../../shared/URL";
import "../details-page.scss";

const ConfirmRPCard = ({content, update, table, close}) => {
    const [open] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);
    const [cardContent, setCardContent] = useState<any>(content);
    const [index, setIndex] = useState<number>(0);
    const tableContent = table.table.content;
    const totalElements = tableContent.length;

    let {id, pointId} = cardContent;

    const coordinates =
        content.requestCoordinates && content.requestCoordinates.split(":");

    const closeLastCard = () => {
        SuccessDataSaveNotify(notificationMessages.noMoreRetailPointsToConfirm);
        setTimeout(() => close(), 3000);
    };

    const showNextCard = () => {
        setIndex(index + 1);
        index + 1 >= totalElements
            ? closeLastCard()
            : tableContent[index + 1] &&
            setCardContent(tableContent[index + 1]);
    };

    const _reject = () => {
        setLoading(true);
        client
            .post(url.rejectProposalById + id)
            .then(() => {
                SuccessDataSaveNotify();
                showNextCard();
                update();
            })
            .catch(() => {
            })
            .finally(() => setLoading(false));
    };

    const _confirm = (values) => {
        const {adminPercentOfBonus} = values;
        setLoading(true);
        client
            .post(url.confirmProposalById + id)
            .then(() => client.post(url.editRetailPoint + pointId,
                {adminPercentOfBonus}))
            .then(() => {
                SuccessDataSaveNotify(notificationMessages.retailPointConfirmMessage);
                showNextCard();
                update();
            })
            .catch(() => {})
            .finally(() => setLoading(false));
    };

    const getFullName = (value) => {
        const {username, surname, noPatronymic, patronymic} = value;
        return `${username} ${surname}${!noPatronymic && ' ' + patronymic}`
    };

    return (
        <Modal closeIcon open={open} onClose={close} className="details-modal">
            <Modal.Header>Информация о розничной точке</Modal.Header>
            <Modal.Content className="details-modal__content">
                <Formik
                    onSubmit={() => {}}
                    validateOnChange={false}
                    enableReinitialize={true}
                    initialValues={{
                        adminPercentOfBonus: cardContent.adminPercentOfBonus || 10
                    }}
                    validationSchema={Yup.object().shape({
                        adminPercentOfBonus: Yup.number()
                            .typeError(validationErrorMessages.pointAdminPercentIsNumber)
                            .min(0, validationErrorMessages.pointAdminPercentMin)
                            .max(110, validationErrorMessages.pointAdminPercentMax)
                    })}>
                    {({values, errors, handleChange, handleSubmit}) => {
                        return <>
                            <Form onSubmit={handleSubmit} noValidate>
                                <Grid className="details-modal__grid" stackable>
                                    <DetailsFieldComponent
                                        value={cardContent.retailPoint.inn}
                                        name="inn"
                                        placeholder="ИНН организации"
                                        disabled
                                    />
                                    <DetailsFieldComponent
                                        value={cardContent.retailPoint.name}
                                        name="name"
                                        placeholder="Название точки"
                                        disabled
                                    />
                                    <DetailsFieldComponent
                                        value={cardContent.retailPoint.directorFullName}
                                        name="directorFullName"
                                        placeholder="ФИО руководителя"
                                        disabled
                                    />
                                    <DetailsFieldComponent
                                        value={getFullName(cardContent.user)}
                                        name="userFullName"
                                        placeholder="ФИО АРТ"
                                        disabled
                                    />
                                    <DetailsFieldComponent
                                        value={cardContent.retailPoint.directorPhone}
                                        name="directorPhone"
                                        placeholder="Номер телефона руководителя"
                                        disabled
                                    />
                                    <DetailsFieldComponent
                                        value={cardContent.retailPoint.address}
                                        name="address"
                                        placeholder="Адрес"
                                        disabled
                                    />
                                    <DetailsFieldComponent
                                        value={values.adminPercentOfBonus}
                                        error={errors.adminPercentOfBonus}
                                        onChange={handleChange}
                                        name="adminPercentOfBonus"
                                        placeholder="Процент АРТ"
                                        icon="percent"
                                        type="number"
                                    />
                                    {coordinates && (
                                        <Grid.Row>
                                            <Grid.Column width={16}>
                                                <h3>На карте:</h3>
                                                <YMaps>
                                                    <Map
                                                        defaultState={{
                                                            center: coordinates,
                                                            zoom: 9,
                                                            controls: ["fullscreenControl"]
                                                        }}
                                                        modules={[
                                                            "control.FullscreenControl"
                                                        ]}
                                                        className="map"
                                                    >
                                                        <Placemark
                                                            defaultGeometry={coordinates}
                                                        />
                                                    </Map>
                                                </YMaps>
                                            </Grid.Column>
                                        </Grid.Row>
                                    )}
                                    <Grid.Column
                                        width="16"
                                        className="details-modal__buttons"
                                    >
                                        <Button
                                            labelPosition="right"
                                            onClick={_reject}
                                            loading={loading}
                                            icon
                                        >
                                            Отклонить
                                            <Icon name="close"/>
                                        </Button>
                                        <Button
                                            className="button_positive"
                                            labelPosition="right"
                                            onClick={_confirm}
                                            loading={loading}
                                            icon
                                        >
                                            Подтвердить
                                            <Icon name="arrow right"/>
                                        </Button>
                                    </Grid.Column>
                                </Grid>
                            </Form>
                        </>
                    }}
                </Formik>
            </Modal.Content>
        </Modal>
    );
};

export default ConfirmRPCard;
