import React from "react";
import { Grid, Input, Select } from "semantic-ui-react";
import FiltersComponent from "../../../components/FiltersComponent";
import CustomTable from "../../../components/CustomTable/CustomTable";
import TableStats from "../../../components/TableStats";
import useFetchTable from "../../../hooks/useFetchTable";
import { RangeDateInput } from "../../../components/FiltersComponent/FilterField";
import { DateTimeCell, StatusCell } from "../../../components/CustomCell";
import SaleCard from "../../Cards/SaleCard";
import { url } from "../../../shared/URL";
import { saleStatusOptions } from "../../../shared/constants";


const SalesPage = () => {
	const fetchTable = useFetchTable(url.getSales);

	return <div className="page table-page">
		<h1 className="page__header">Список продаж</h1>
		<Grid stackable>
			<Grid.Row>
				<Grid.Column className="table-page__stats">
					<TableStats totalElements={fetchTable.totalElements} />
				</Grid.Column>
			</Grid.Row>
			<Grid.Row>
				<Grid.Column width={3}>
					<FiltersComponent applyFilters={fetchTable._apply} totalElements={fetchTable.totalElements}>
						<Input type="text" name="modelName" placeholder="Наименование инструмента" />
						<Input type="text" name="sn" placeholder="Серийный номер" />
						<Select placeholder="Статус продажи" options={saleStatusOptions} name="saleStatus" clearable multiple />
						<Input type="text" name="retailInn" placeholder="ИНН организации" />
						<Input type='text' name='sellerFullName' placeholder='ФИО продавца' />
						<Input type='number' name='mobile' placeholder='Номер телефона' />
						<RangeDateInput name="createdTime" placeholder="Дата продажи" />
					</FiltersComponent>
				</Grid.Column>
				<Grid.Column width={13}>
					<CustomTable
						table={fetchTable}
						columns={columns}
						CardComponent={SaleCard}
					/>
				</Grid.Column>
			</Grid.Row>
		</Grid>
	</div>;
};

export default SalesPage;

const columns = [
	{ Header: "Наименование инструмента", accessor: "modelName" },
	{ Header: "Серийный номер", accessor: "sn" },
	{ Header: "Статус продажи", accessor: "saleStatus", Cell: row => StatusCell(row, saleStatusOptions) },
	{ Header: "Дата продажи", accessor: "createdTime", Cell: row => DateTimeCell(row) },
	{ Header: "ИНН организации", accessor: "retailInn" },
	{ Header: "Цена из чека", accessor: "price" },
];
