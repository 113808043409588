import React, { useEffect, useState } from "react";
import "./dialogs.scss";


interface DialogsProps {
  header: string,
  content?: any,
  setChatId: Function,
  count: number,
  update: Function
}


const QuantityMessages = ({ chat }) => {
  const [quantity, setQuantity] = useState<number | string>("");
  useEffect(() => {
    const result = chat.messages.filter(message => !message.isRead);
    setQuantity(result.length);
  }, []);
  return (
    <div className="dialogs__item__header--quantity">{quantity}</div>
  );
};

const Dialogs = ({ header, content, setChatId }: DialogsProps) => {

  const openChat = (chat) => {
    setChatId(chat.id);

  };

  return (
    <div className="dialogs">
      <div className="dialogs__header">
        {header}
      </div>
      <div className="dialogs__items">
        {content && content.map(chat => {
          return (
            <div
              className="dialogs__item"
              key={chat.id}
              onClick={() => openChat(chat)}
            >
              <div className="dialogs__item__header">
                <div className="dialogs__item__header--name">
                  {`${chat.user.surname} ${chat.user.username} ${chat.user.patronymic}`}
                </div>
                <QuantityMessages chat={chat} />
              </div>
              <div className="dialogs__item__message">{chat.messages[chat.messages.length - 1].message}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Dialogs;
