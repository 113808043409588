import React from "react";
import { Grid, Input, Select } from "semantic-ui-react";
import CustomTable from "../../../components/CustomTable/CustomTable";
import FiltersComponent from "../../../components/FiltersComponent";
import { RangeDateInput } from "../../../components/FiltersComponent/FilterField";
import TableStats from "../../../components/TableStats";
import ConfirmSaleCard from "../../Cards/SaleCard/ConfirmSaleCard";
import { DateTimeCell } from "../../../components/CustomCell";
import useFetchTable from "../../../hooks/useFetchTable";
import { filterURL, url } from "../../../shared/URL";
import { saleStatusOptions } from "../../../shared/constants";

const NotConfirmedSales = () => {
    const defaultSortParams = {
        sortBy: "updateTime",
        sortDirection: "desc",
    };

    const fetchTable = useFetchTable(url.getSales, filterURL.notConfirmedSales, false, defaultSortParams);

    return <div className="page table-page">
        <h1 className="page__header">Подтверждение налогового статуса продаж</h1>
        <Grid stackable>
            <Grid.Row>
                <Grid.Column className="table-page__stats">
                    <TableStats totalElements={fetchTable.totalElements}/>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={3}>
                    <FiltersComponent applyFilters={fetchTable._apply} totalElements={fetchTable.totalElements}>
                        <Input type="text" name="modelName" placeholder="Наименование инструмента"/>
						<Select placeholder="Статус продажи" 
								defaultValue="PENDING"
								name="saleStatus" 
								options={saleStatusOptions}
								clearable 
								multiple
								disabled/>
                        <RangeDateInput name="updateTime" placeholder="Дата продажи"/>
                        <Input type="text" name="retailInn" placeholder="ИНН организации"/>
                    </FiltersComponent>
                </Grid.Column>
                <Grid.Column width={13}>
                    <CustomTable table={fetchTable}
                                 columns={columns}
                                 CardComponent={ConfirmSaleCard}
                                 // default sorted - чтобы отображалась сортировка по умолчанию
                                 defaultSorted={[
                                     {
                                         id: "updateTime",
                                         desc: true,
                                     }
                                 ]}
                                />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    </div>;
};

export default NotConfirmedSales;

const columns = [
    {Header: "Наименование инструмента", accessor: "modelName"},
    {Header: "Дата продажи", accessor: "updateTime", Cell: row => DateTimeCell(row)},
    {Header: "ID продажи", accessor: "id"},
];
