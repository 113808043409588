import React from "react";

const TableStats = (props) => {
    return <div className="table-stats">
        Найдено {props.totalElements || 0} запис
        {
            props.totalElements < 5 && props.totalElements !== 0 ? 'и'
                : 'ей'
        }.
    </div>;
};

export default TableStats;