import React from "react";
import { Checkbox, Grid, Input, Select } from "semantic-ui-react";
import useFetchTable from "../../../hooks/useFetchTable";
import FiltersComponent from "../../../components/FiltersComponent";
import CustomTable from "../../../components/CustomTable/CustomTable";
import TableStats from "../../../components/TableStats";
import { BooleanCell, StatusCell } from "../../../components/CustomCell";
import SendNotificationModal from "../../../components/SendNotificationModal";
import FileUploadButton from "../../../components/FileUploadButton";
import UserCard from "../../Cards/UserCard";
import { url } from "../../../shared/URL";
import { taxStatusOptions, userStatusOptions } from "../../../shared/constants";


const UsersPage = () => {
	const fetchTable = useFetchTable(url.getUsers);

	return (
		<div className='page table-page'>
			<h1 className='page__header'>Список пользователей</h1>
			<Grid stackable>
				<Grid.Row>
					<Grid.Column className='table-page__stats'>
						<TableStats totalElements={fetchTable.totalElements} />
					</Grid.Column>
				</Grid.Row>
				<Grid.Row>
					<Grid.Column width={3}>
						<FiltersComponent
							applyFilters={fetchTable._apply}
							totalElements={fetchTable.totalElements}
							render={SendNotificationModal}
						>
							<Input
								type='text'
								name='surnameWithInitials'
								placeholder='ФИО пользователя'
							/>
							<Input
								type='number'
								name='mobile'
								placeholder='Номер телефона'
							/>
							<Select
								placeholder='Статус пользователя'
								options={userStatusOptions}
								name='status'
								clearable
								multiple
							/>
							<Select
								placeholder='Статус ФНС'
								options={taxStatusOptions}
								name='taxStatus'
								clearable
								multiple
							/>
							<Checkbox
								name='locked'
								label='Пользователь заблокирован'
							/>
							<Checkbox
								name='taxLocked'
								label='Вывод бонусов заблокирован'
							/>
						</FiltersComponent>
						<FileUploadButton
							url={url.addBonusesToList}
							buttonName={"Начислить бонусы по списку"}
							update={fetchTable._fetch}
						/>
					</Grid.Column>
					<Grid.Column width={13}>
						<CustomTable
							table={fetchTable}
							columns={columns}
							CardComponent={UserCard}
						/>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</div>
	);
};

export default UsersPage;

const columns = [
	{ Header: "ФИО пользователя", accessor: "surnameWithInitials" },
	{ Header: "Номер телефона", accessor: "mobile" },
	{ Header: "Статус пользователя", accessor: "status", Cell: row => StatusCell(row, userStatusOptions) },
	{
		Header: "Статус ФНС",
		accessor: "taxStatus",
		Cell: (row) => StatusCell(row, taxStatusOptions),
	},
	{
		Header: "Блокировка пользователя",
		accessor: "locked",
		Cell: (row) => BooleanCell(row),
	},
	{
		Header: "Блокировка вывода бонусов",
		accessor: "taxLocked",
		Cell: (row) => BooleanCell(row),
	},
	{ Header: "Начисленные бонусы", accessor: "chargedBonus" },
	{ Header: "Выведенные бонусы", accessor: "dischargedBonus" },
];
