import axios, {AxiosError} from 'axios';
import {Auth} from "../pages/MainPage";
import {errorNotify, uniqueErrorNotify} from "../components/NotificationsEmitter";

export const client = axios.create({
    withCredentials: true,
    baseURL: '/web',
    headers: {
      'Access-Control-Allow-Origin': 'https://dev.a.is.mv1f.ru/'
    }
});

client.interceptors.response.use(
    undefined,
    (error: AxiosError): Promise<AxiosError> => {
        if (error && error.response) {
            if (error.response.status === 401) {
                Auth.auth = false;
                uniqueErrorNotify('Ошибка', error.response.data.message)
            }
            else {
                errorNotify('Ошибка', error.response.data.message);
            }
        }
        return Promise.reject(error);
    }
);
