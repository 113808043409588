export const navigation = {
    auth: '/auth',
    registration: '/auth/registration',
    requestPasswordReset: '/auth/reset',
    resetPassword: '/auth/reset/:id',
    models: '/models',
    tools: '/tools',
    sellers: '/sellers',
    buyers: '/buyers',
    users: '/users',
    admins: '/admins',
    notConfirmedUsers: '/confirm-users',
    sales: '/sales',
    payments: '/payments',
    retailPoints: '/retail-points',
    receipts: '/receipts',
    notConfirmedRP: '/confirm-retail-points',
    notConfirmedSales: '/confirm-sales',
    notConfirmedSalesUnknownTools: '/confirm-sales-unknown-tools',
    reports: '/reports',
    activeDialogs: '/activeDialogs',
    completedDialogs: '/completedDialogs',
};

export const url = {
    login: '/login',
    logout: '/logout',
    registration: '/registration',
    resetRequestPassword: '/admin/reset/request/',
    resetPassword: '/admin/reset/',
    checkResetCode: '/admin/reset/check/',
    getProposals: '/proposals/all',
    getAdmins: '/admin/all',
    disableAdminByEmail: '/admin/disable/',
    enableAdminByEmail: '/admin/enable/',
    registerAdmin: '/admin/registration',
    confirmProposalById: '/admin/retail/point/admin/confirm/',
    rejectProposalById: '/admin/retail/point/admin/reject/',
    changeRetailPointAdmin: '/admin/retail/point/admin/change/',
    getTools: '/tool/all',
    getToolByBuyerId: '/tool/buyer/',
    importToolToDB: '/tool/import',
    getModels: '/model/all',
    changeBonuses: '/model/bonus/change',
    editModel: '/model/edit/',
    editTool: '/tool/edit/',
    importModelToDB: '/model/import',
    updateModelToDB: '/model/update',
    getPoints: '/point/all',
    blockPoint: '/point/block/',
    unblockPoint: '/point/unblock/',
    editRetailPoint: '/point/edit/',
    getPointSellers: '/point/sellers/',
    getPointByInn: '/point/inn/',
    findPointAddress: '/point/search',
    getCurrentPointAdmin: '/point/admin/',
    getProposalsCount: '/proposals/count',
    addSellerToPoint: '/admin/seller/add',
    removeSellerFromPoint: '/admin/seller/deactivate/',
    getSales: '/sale/all',
    getSalesUnknownTools: '/unknown-tools/all',
    editUnknownTool: '/unknown-tools',
    cancelUnknownTool: '/unknown-tools/reject/',
    saveNewModel: '/model',
    getSalesCount: '/sale/count',
    getSalesUnknownCount: '/unknown-tools/count',
    getSellerHistoryById: '/sale/seller/',
    addItemToSale: '/sale/add/item',
    rejectSale: '/sale/fail',
    getReceipts: '/receipt/all',
    getReceiptBySaleId: '/receipt',
    getPayments: '/payment/all',
    getUsers: '/user/all',
    blockUser: '/user/block/',
    unblockUser: '/user/unblock/',
    editUser: '/user/edit/',
    taxBlockUser: '/user/tax/block/',
    taxUnblockUser: '/user/tax/unblock/',
    taxConfirmUser: '/user/tax/confirm/',
    taxRejectUser: '/user/tax/reject/',
    getUserCount: '/user/count',
    findUserByNumber: '/user/mobile/',
    pushMessage: '/user/push',
    pushForAll: '/push/for-all',
    pushMessageCsv: '/push',
    getSellers: '/user/seller/all',
    resetUserPassword: '/user/password/drop/',
    getReports: '/report',
    files: '/files/',
    chatsAnswered: '/chats/answered',
    chatsActive: '/chats/active',
    chatsProcess: '/chats/process',
    chats: '/chats',
    addBonus: '/bonus/add',
    addBonusesToList: '/bonus/import',
};

export const filterURL = {
    sellers: 'status$SELLER',
    buyers: 'status$BUYER',
    notConfirmedSales: 'saleStatus$PENDING',
    notConfirmedRP: 'confirm$false',
    roleAdmin: 'role$ADMIN_RT',
    inactiveRP: 'active$true',
    notConfirmedUsers: 'taxStatus$MANUAL_CHECK',
    notKnown: 'isKnown$false',
    isNotRejected: 'isRejected$false',
    prefix: '?filters=',
    separator: ';'
};
