import React from "react";
import { Grid, Input, Select } from "semantic-ui-react";
import { RangeDateInput, RangeInput } from "../../../components/FiltersComponent/FilterField";
import FiltersComponent from "../../../components/FiltersComponent";
import CustomTable from "../../../components/CustomTable/CustomTable";
import TableStats from "../../../components/TableStats";
import useFetchTable from "../../../hooks/useFetchTable";
import { DateTimeCell, StatusCell } from "../../../components/CustomCell";
import { url } from "../../../shared/URL";
import { receiptStatusOptions } from "../../../shared/constants";


const ReceiptsPage = () => {
  const fetchTable = useFetchTable(url.getReceipts);

  return (
    <div className="page table-page">
      <h1 className="page__header">Список чеков</h1>
      <Grid stackable>
        <Grid.Row>
          <Grid.Column className="table-page__stats">
            <TableStats totalElements={fetchTable.totalElements} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={3}>
            <FiltersComponent
              applyFilters={fetchTable._apply}
              totalElements={fetchTable.totalElements}
            >
              <RangeDateInput
                name="dateTime"
                placeholder="Дата чека"
              />
              <Select
                placeholder="Статус чека"
                options={receiptStatusOptions}
                name="receiptStatus"
                clearable
                multiple
              />
              <RangeInput
                type="range"
                name="totalSum"
                placeholder="Сумма чека"
              />
              <Input
                type="number"
                name="mobile"
                placeholder="Номер телефона продавца"
              />
              <Input
                type="number"
                name="sn"
                placeholder="Серийный номер"
              />
              <Input
                type="number"
                name="toolId"
                placeholder="Id инструмента"
              />
              <Input
                type="number"
                name="saleId"
                placeholder="Id продажи"
              />
            </FiltersComponent>
          </Grid.Column>
          <Grid.Column width={13}>
            <CustomTable
              table={fetchTable}
              columns={columns}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default ReceiptsPage;

const columns = [
  {
    Header: "ID продажи",
    id: "salesId",
    accessor: (row) => row.salesId.map((saleId, index) => <>{saleId + (row.salesId.length !== index + 1 ? ", " : "")}</>),
  },
  {
    Header: "Дата чека",
    accessor: "dateTime",
    Cell: (row) => DateTimeCell(row),
  },
  {
    Header: "Статус чека (ФНС)",
    accessor: "receiptStatus",
    Cell: (row) => StatusCell(row, receiptStatusOptions),
  },
  {
    Header: "ФН",
    accessor: "fn",
  },
  {
    Header: "ФД",
    accessor: "fd",
  },
  {
    Header: "ФП",
    accessor: "fp",
  },
  { Header: "Сумма", accessor: "totalSum" },
];
