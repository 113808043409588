import { format } from "date-fns";


export const formatDateTime = (timestamp: string) => (timestamp ? format(new Date(timestamp), "dd/MM/yyyy HH:mm") : "");

export const formatDate = (timestamp: string) => (timestamp ? format(new Date(timestamp), "yyyy-MM-dd") : "");

export const formatTimeMessage = (timestamp: string) => (timestamp ? format(new Date(timestamp), "dd.MM.yyyy HH:mm") : "");

export const getModifiedValues = (values, initialValues): Object => {
	const modifiedValues: Object = {};
	for ( let fieldName in values ) {
		if (values[fieldName] !== initialValues[fieldName]) {
			modifiedValues[fieldName] = values[fieldName];
		}
	}
	return modifiedValues;
};

/*
 если в keys (объект Options) есть текст, выведет текст вместо значения
 если нету, вернет значение
 */
export const getOptionText = (value, options): void => {
	for ( let key in options ) {
		if (options[key].value === value) {
			return options[key].text;
		}
	}
	return value;
};

export const downloadBlob = (file: Blob, fileName: string): void => {
	let url = window.URL.createObjectURL(file);
	let a = document.createElement("a");
	a.href = url;
	a.download = fileName;
	a.click();
};

// достает название файла из хедера вида "attachment; filename="user-2020-06-16T09:17:43.618849.csv"
export const getFileNameFromHeader = (headerContent: string, defaultFileName?: string): string => {
	const index = headerContent.indexOf("=") + 1;
	// если в строке нет '=', вернуть дефолтное значение (или исходный хедер)
	if (index === -1) {
		return defaultFileName || headerContent;
	}
	// убрать лишние " из имени файла
	return headerContent.slice(index).split("\"").join("");
};

export const userStatusInnerText = (value, options) => {
	return value ? options.filter((item) => item.value === value)[0].text : "Нет данных";
};

export const findChat = (chats, chatId) => chats[chats.findIndex((el => el.id === chatId))];
