import React from "react";
import CustomTable from "../../../../../components/CustomTable/CustomTable";
import RemoveSeller from "../../RemoveSeller";
import AddSeller from "../../AddSeller";

const SellersTableComponent = ({id, sellersTable, update}) => {
    const sellersTableColumns = [
        {Header: "ФИО продавца", accessor: "surnameWithInitials"},
        {
            Header: "Статус",
            accessor: "roles",
            Cell: row => row.value && [...row.value]
        },
        {Header: "Количество продаж", accessor: "salesAmount"},
        {Header: "Количество бонусов", accessor: "chargedBonus"}
    ];

    return (
        <React.Fragment>
            <h3>Продавцы</h3>
            <CustomTable
                table={sellersTable}
                columns={sellersTableColumns}
                defaultPageSize={0}
                sortable={false}
                className='details-modal__table'
            />
            <div className='details-modal__buttons'>
                <RemoveSeller
                    retailPointId={id}
                    update={() => {
                        sellersTable._fetch();
                        update();
                    }}
                />
                <AddSeller
                    retailPointId={id}
                    update={() => {
                        sellersTable._fetch();
                        update();
                    }}
                />
            </div>
        </React.Fragment>
    );
};

export default SellersTableComponent;