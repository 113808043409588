import React, {useEffect, useState} from "react";
import {Button, Icon} from "semantic-ui-react";
import ReactFileReader from 'react-file-reader';
import {successNotify} from "../NotificationsEmitter";
import {client} from "../../shared/client";
import './file-upload.scss';

const SuccesfullFileUpload = (payload) => {
    return successNotify('Файл успешно загружен', `Добавлено ${payload[1]} из ${payload[0]} позиций`);
};

interface Props {
    buttonName?: string,
    url: string,
    update: Function,
}

const FileUploadButton = ({url, update, buttonName}: Props) => {
    const [files, setFiles] = useState<any>(undefined);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if (files) {
            const formData = new FormData();
            formData.append('files', files[0]);
            setLoading(true);
            client.post(url, formData, {
                headers: {'Content-Type': 'multipart/form-data'}
            })
                .then((response) => {
                    SuccesfullFileUpload(response.data.payload);
                    update();
                })
                .catch(() => {
                })
                .finally(() => {
                    setFiles(undefined);
                    setLoading(false);
                })
        }
    }, [files]);

    return <ReactFileReader handleFiles={setFiles} fileTypes={[".csv", ".xls", ".xlsx"]}>
        <Button icon loading={loading} labelPosition="right" className="file-upload-button">
            {buttonName ? buttonName : 'Загрузить файл'}
            <Icon name="file" />
        </Button>
    </ReactFileReader>
};

export default FileUploadButton;
