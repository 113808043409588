import React, { useState } from "react";
import { Button, Dropdown, Form, Grid, Icon, Modal, Popup } from "semantic-ui-react";
import { Formik } from "formik";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import DetailsFieldComponent from "../DetailsFieldComponent";
import SuccessDataSaveNotify from "../SuccessDataSaveNotify";
import { successNotify } from "../../../components/NotificationsEmitter";
import { userStatusOptions, validationErrorMessages } from "../../../shared/constants";
import { userStatusInnerText } from "../../../shared/utils";
import { client } from "../../../shared/client";
import { url } from "../../../shared/URL";
import "../details-page.scss";


const UnknownToolCard = props => {
  const { content, update, close } = props;

  const [open] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [models, setModels] = useState(content.models);

  let initialValues = {};

  if (models) {
    if (models.length > 0) {
      initialValues = {
        initiatorName: content.initiatorName,
        initiatorStatus: content.initiatorStatus,
        initiatorMobile: content.initiatorMobile,
        nfc: content.nfc || null,
        sn: content.sn,
        id: content.id,
        modelId: models[0].id,
        productionDate: content.productionDate,
        name: models[0].name,
      };
    } else {
      initialValues = {
        initiatorName: content.initiatorName,
        initiatorStatus: content.initiatorStatus,
        initiatorMobile: content.initiatorMobile,
        nfc: content.nfc || null,
        sn: content.sn || "",
        id: content.id,
        modelId: "",
        productionDate: "",

        vendorCode: "",
        name: "",
        url: "",
        typeName: "",
        msrp: "",
        buyerBonus: "",
        sellerBonus: "",
        deviationPercent: "",
      };
    }
  }

  const getModels = () => {
    return models.map((model, index) => {
      return {
        key: index,
        text: `${model.id} | ${model.name}`,
        value: model.id,
      };
    });
  };

  const cancelChanges = () => {
    setLoading(true);
    client.post(`${url.cancelUnknownTool}${content.id}`)
          .then(() => {
            successNotify("Успех", "Инструмент отменен");
            update();
          })
          .catch(() => {
          })
          .finally(() => {
            setLoading(false);
            close();
          });
  };

  const _save = values => {
    setLoading(true);
    if (models.length === 0) {
      const data = {
        vendorCode: values.vendorCode,
        name: values.name,
        url: values.url,
        typeName: values.typeName,
        msrp: values.msrp,
        buyerBonus: values.buyerBonus,
        sellerBonus: values.s,
        deviationPercent: values.deviationPercent,
      };
      client
      .post(url.saveNewModel, data)
      .then(() => {
        SuccessDataSaveNotify();
        update();
      })
      .catch(() => {
      })
      .finally(() => {
        setLoading(false);
        close();
      });
    } else {
      client
      .put(`${url.editUnknownTool}/${values.id}`, values)
      .then(() => {
        SuccessDataSaveNotify();
        update();
      })
      .catch(() => {
      })
      .finally(() => {
        setLoading(false);
        close();
      });
    }
  };

  return (
    <Modal open={open} onClose={close} closeIcon className="details-modal">
      <Modal.Header>{models.length === 0
        ? "Ручное добавление модели и информации"
        : "Добавление информации о товаре"}
      </Modal.Header>
      <Modal.Content className="details-modal__content">
        <Formik
          initialValues={initialValues}
          onSubmit={_save}
          validationSchema={models.length ? Yup.object().shape({
              nfc: Yup.string().nullable(),
              modelId: Yup.string().required(validationErrorMessages.required),
              sn: Yup.string().required(validationErrorMessages.required),
              productionDate: Yup.string().required(validationErrorMessages.required),
            })
            : Yup.object().shape({
              vendorCode: Yup.string().required(validationErrorMessages.required),
              name: Yup.string().required(validationErrorMessages.required),
              url: Yup.string().required(validationErrorMessages.required),
              typeName: Yup.string().required(validationErrorMessages.required),
              msrp: Yup.string().required(validationErrorMessages.required),
              buyerBonus: Yup.string().required(validationErrorMessages.required),
              sellerBonus: Yup.string().required(validationErrorMessages.required),
              deviationPercent: Yup.string().required(validationErrorMessages.required),
            })
          }
          validateOnChange={false}
          key={content.id}
        >
          {({ values, errors, handleChange, handleSubmit, setFieldValue }: any) => {
            return (
              <Form onSubmit={handleSubmit} noValidate>
                <Grid className="details-modal__grid" stackable>
                  {models.length === 0
                    ? <>
                      <DetailsFieldComponent
                        value={values.initiatorName}
                        name="initiatorName"
                        placeholder="Инициатор"
                        disabled
                      />
                      <DetailsFieldComponent
                        value={values.initiatorStatus && userStatusInnerText(values.initiatorStatus, userStatusOptions)}
                        name="initiatorStatus"
                        placeholder="Статус иницитора"
                        disabled
                      />
                      <DetailsFieldComponent
                        value={values.initiatorMobile}
                        name="initiatorMobile"
                        placeholder="Номер телефона инициатора"
                        disabled
                      />
                      <DetailsFieldComponent
                        value={values.nfc}
                        name="nfc"
                        placeholder="NFC"
                        disabled
                      />
                      <DetailsFieldComponent
                        value={values.sn}
                        name="sn"
                        placeholder="Серийный номер"
                        disabled
                      />
                      <DetailsFieldComponent
                        value={values.vendorCode}
                        error={errors.vendorCode}
                        onChange={handleChange}
                        name="vendorCode"
                        placeholder="Артикул"
                      />
                      <DetailsFieldComponent
                        value={values.name}
                        error={errors.name}
                        onChange={handleChange}
                        name="name"
                        placeholder="Наименование модели"
                      />
                      <DetailsFieldComponent
                        value={values.url}
                        error={errors.url}
                        onChange={handleChange}
                        name="url"
                        placeholder="Ссылка на документацию"
                      />
                      <DetailsFieldComponent
                        value={values.typeName}
                        error={errors.typeName}
                        onChange={handleChange}
                        name="typeName"
                        placeholder="Категория"
                      />
                      <DetailsFieldComponent
                        value={values.msrp}
                        error={errors.msrp}
                        type="number"
                        onChange={handleChange}
                        name="msrp"
                        placeholder="РРЦ"
                      />
                      <DetailsFieldComponent
                        value={values.buyerBonus}
                        error={errors.buyerBonus}
                        type="number"
                        onChange={handleChange}
                        name="buyerBonus"
                        placeholder="Бонус покупателя"
                      />
                      <DetailsFieldComponent
                        value={values.sellerBonus}
                        error={errors.sellerBonus}
                        type="number"
                        onChange={handleChange}
                        name="sellerBonus"
                        placeholder="Бонус продавца"
                      />
                      <DetailsFieldComponent
                        value={values.deviationPercent}
                        error={errors.deviationPercent}
                        type="number"
                        onChange={handleChange}
                        name="deviationPercent"
                        placeholder="Процент отклонения"
                      />
                    </>
                    : <>
                      <DetailsFieldComponent
                        value={values.initiatorName}
                        name="initiatorName"
                        placeholder="Инициатор"
                        disabled
                      />
                      <DetailsFieldComponent
                        value={values.initiatorStatus && userStatusInnerText(values.initiatorStatus, userStatusOptions)}
                        name="initiatorStatus"
                        placeholder="Статус иницитора"
                        disabled
                      />
                      <DetailsFieldComponent
                        value={values.initiatorMobile}
                        name="initiatorMobile"
                        placeholder="Номер телефона инициатора"
                        disabled
                      />
                      <DetailsFieldComponent
                        value={values.nfc}
                        error={errors.nfc}
                        onChange={handleChange}
                        name="nfc"
                        placeholder="NFC"
                      />
                      <DetailsFieldComponent
                        value={values.sn}
                        error={errors.sn}
                        onChange={handleChange}
                        name="sn"
                        placeholder="Серийный номер"
                      />
                      {models.length === 1
                        ? <DetailsFieldComponent
                          value={`${values.modelId} | ${values.name}`}
                          error={errors.modelId}
                          onChange={handleChange}
                          name="modelId"
                          placeholder="ID модели"
                          disabled
                        />
                        : <DetailsFieldComponent
                          placeholder="ID модели"
                          disabled
                          hideP
                          children={<Dropdown
                            placeholder="Выберете ID модели из предложенных"
                            onChange={(e, data) => {
                              setFieldValue("modelId", data.value);
                            }}
                            defaultValue={values.modelId}
                            error={errors.modelId}
                            name="modelId"
                            fluid
                            selection
                            options={getModels()}
                          />}
                        />}
                      <DetailsFieldComponent
                        value={values.productionDate}
                        error={errors.productionDate}
                        name="productionDate"
                        onChange={handleChange}
                        placeholder="Дата производства"
                        renderCustomInput={() => (
                          <>
                            <Popup
                              content={errors.productionDate}
                              open={errors.productionDate}
                              position="bottom center"
                              className="error-popup"
                              trigger={<DatePicker
                                required
                                selected={values.productionDate &&
                                new Date(
                                  values.productionDate,
                                )
                                }
                                onChange={(date) =>
                                  setFieldValue(
                                    "productionDate",
                                    date,
                                  )
                                }
                                dateFormat="dd/MM/yy"
                                placeholderText="Дата производства"
                              />}
                            />
                          </>
                        )}
                      />
                    </>
                  }
                  <Grid.Column width="16" className="details-modal__buttons">
                    <Button
                      type="button"
                      onClick={cancelChanges}
                      labelPosition="right"
                      icon
                    >
                      Отменить
                      <Icon name="close" />
                    </Button>
                    <Button
                      type="submit"
                      className="button_positive"
                      labelPosition="right"
                      loading={loading}
                      icon
                    >
                      Сохранить
                      <Icon name="arrow right" />
                    </Button>
                  </Grid.Column>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </Modal.Content>
    </Modal>
  );
};

export default UnknownToolCard;
