import React, { useState } from "react";
import { url } from "../shared/URL";
import { Button, Form, Icon, Modal, TextArea } from "semantic-ui-react";
import { Formik } from "formik";
import * as Yup from "yup";

import { client } from "../shared/client";
import { successNotify } from "./NotificationsEmitter";
import CsvPushButton from "./CsvPushButton";


const SendNotificationModal = (totalElements, filters, update) => {
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const pathName = window.location.pathname;
  const baseFilter = pathName !== "/users" ? (pathName === "/sellers"
    ? "?filters=status$SELLER;"
    : "?filters=status$BUYER;") : "?filters=";



  const onClose = () => {
    setOpen(false);
  };

  const _sendNotification = (values) => {
    const { text: body, header: titleType } = values;
    setLoading(true);


    client.post(`${pathName === "/users" && !filters ? url.pushForAll : url.pushMessage + baseFilter + filters}`, {
      body,
      titleType,
    })
          .then(() => {
            setOpen(false);
            successNotify("Успешно", "Уведомления были успешно отправлены");
            update();
          })
          .catch(() => {
          })
          .finally(() => {
            setLoading(false);
          });
  };

  return (
    <Modal
      trigger={
        <Button
          className="filters__button filters__modal-trigger"
          labelPosition="right"
          icon
        >
          Отправить уведомление
          <Icon name="mail" />
        </Button>
      }
      closeIcon
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      size="small"
      className="modal"
    >
      <Formik
        initialValues={{ header: "", text: "" }}
        onSubmit={_sendNotification}
        validateOnChange={false}
        validationSchema={Yup.object().shape({
          header: Yup.string().required("Обязательное поле."),
          text: Yup.string().required("Обязательное поле."),
        })}
      >
        {({ values, errors, handleChange, handleSubmit }: any) => {
          return (
            <>
              <Modal.Content>
                <Form onSubmit={handleSubmit}>
                  <Form.Input
                    type="text"
                    placeholder="Заголовок"
                    name="header"
                    value={values.header}
                    error={errors.header}
                    onChange={handleChange}
                  />
                  <Form.Field
                    control={TextArea}
                    placeholder="Текст уведомления"
                    name="text"
                    value={values.text}
                    error={errors.text}
                    onChange={handleChange}
                  />
                </Form>
              </Modal.Content>
              <Modal.Actions>
                <Button
                  type="submit"
                  labelPosition="right"
                  className="button_positive"
                  icon
                  loading={loading}
                  onClick={handleSubmit}
                >
                  Отправить {totalElements} пользователям
                  <Icon name="arrow right" />
                </Button>
                <CsvPushButton values={values} onClose={onClose} />
              </Modal.Actions>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default SendNotificationModal;
