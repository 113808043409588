import React, { useState } from "react";
import { Modal } from "semantic-ui-react";
import Messages from "../../SupportPage/Chat/Messages";
import UserInfo from "../../SupportPage/UserInfo";
import { userStatusInnerText } from "../../../shared/utils";
import { taxStatusOptions, userStatusOptions } from "../../../shared/constants";
import "./CompletedChatCard.scss";


const CompletedChatCard = ({ close, content }) => {
  const [open] = useState<boolean>(true);

  return (
    <Modal
      open={open}
      onClose={() => {
        close();
      }}
      closeIcon
      size="large"
    >
      <Modal.Header>Завершенный диалог</Modal.Header>
      <Modal.Content>
        <div className="chat-complete">

          <div className="chat-complete__history">

            <Messages messages={content.messages} chatId={content.id} />
          </div>
          <div className="chat-complete__user-info">
            <div>
              <h3>Пользователь:</h3>
            <UserInfo
              placeholder={"ФИО"}
              value={`${content.user.surname} ${content.user.username} ${content.user.patronymic}`}
            />
            <UserInfo
              placeholder={"Статус"}
              value={userStatusInnerText(content.user.status, userStatusOptions)}
            />
            <UserInfo
              placeholder={"Статус ФНС"}
              value={userStatusInnerText(content.user.taxStatus, taxStatusOptions)}
            />
            <UserInfo
              placeholder={"Телефон"}
              value={content.user.mobile}
            />
            </div>
            <div>
              <h3>Админ:</h3>
            </div>
            <UserInfo
              placeholder={"ФИО"}
              value={content.admin.fullName}
            />
            <UserInfo
              placeholder={"Телефон"}
              value={content.admin.mobile}
            />
            <UserInfo
              placeholder={"Email"}
              value={content.admin.email}
            />
          </div>
        </div>
      </Modal.Content>
    </Modal>

  );
};

export default CompletedChatCard;