import React, {useState} from "react";
import {client} from "../../shared/client";
import {url} from "../../shared/URL";
import {Button, Form, Loader, Message} from "semantic-ui-react";
import {Formik} from "formik";
import * as Yup from 'yup';

export const PasswordRecoveryComponent = () => {
    const [errorMessage, setErrorMessage] = useState('');
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);

    const _sendRecoveryLink = ({email}) => {
        setLoading(true);
        client.post(url.resetRequestPassword + email, {email})
            .then(() => setSuccess(true))
            .catch(error => setErrorMessage(error.response.data.message))
            .finally(() => setLoading(false));
    };

    return <React.Fragment>
        <h1 className="authentication-page__header">Восстановить пароль</h1>
        {
            loading ? <Loader active/> :
                success ?
                    <Message positive>
                        <Message.Header>Ссылка была отправлена</Message.Header>
                        <p>Проверьте ваш почтовый ящик.</p>
                    </Message>
                    :
                    <Formik initialValues={{email: ''}} onSubmit={_sendRecoveryLink}
                            validationSchema={Yup.object().shape({
                                email: Yup.string().email('Введите корректный email.').required('Обязательное поле.')
                            })}>
                        {({values, errors, handleChange, handleSubmit}: any) => {
                            return <Form onSubmit={handleSubmit} className="authentication-page__form"
                                         error={errorMessage !== ''}>
                                <Form.Field>
                                        <Form.Input type="email"
                                                    name="email"
                                                    onChange={handleChange}
                                                    value={values.email}
                                                    error={errors.email}
                                                    placeholder="Введите email"/>
                                </Form.Field>
                                <Message error header="Ошибка" content={errorMessage}/>
                                <Button type="submit" className="button authentication-page__button"
                                        onClick={handleSubmit}>
                                    Отправить
                                </Button>
                            </Form>
                        }}
                    </Formik>
        }
    </React.Fragment>
};